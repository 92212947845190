<template>
    <div>
        <div class="header_holder text-center ma">
            <div class="rounder_top  mb-2">
                <div class="card_header ubuntu_b primer">
                    Nova poruka
                </div>
            </div>
        </div>
        <div class="order_holder primer rounder pa-6 px-6">
            <div class="header">
                <form @submit.prevent="submit" :id="form_id">
                    <div class="form_inner">
                        <div class="input_holder border_top">
                            <v-select
                                v-model="message_type"
                                :items="message_types"
                                :error-messages="message_typeErrors"
                                item-text="title"
                                item-value="id"
                                label="Vrsta poruke"
                                dense
                                solo
                            ></v-select>
                        </div>
                        <div class="input_holder border_top" >
                            <v-text-field
                                v-model="subject"
                                :error-messages="subjectErrors"
                                label="Naslov"
                                required
                                :readonly = !subject_show
                                v-eager
                                solo
                                @input="$v.subject.$touch()"
                                @blur="$v.subject.$touch()"
                            ></v-text-field>
                        </div>
                        <div class="border_top">
                            <v-textarea
                                solo
                                v-model="message_text"
                                :error-messages="message_textErrors"
                                label="Vaša poruka"
                                required
                                @input="$v.message_text.$touch()"
                                @blur="$v.message_text.$touch()"
                            ></v-textarea>
                        </div>
                        <v-btn class="rounder submit_btn ubuntu_b" color="secondary" type="submit" :disabled="submitStatus === 'PENDING'">
                            Pošalji poruku
                        </v-btn>
                    </div>
                    <div class="text-center pt-2">
                        <p class="typo__p" v-if="submitStatus === 'OK'">Sve je u redu</p>
                        <p class="typo__p error-txt" v-if="submitStatus === 'ERROR'">Niste pravilno popunili formu</p>
                        <p class="typo__p" v-if="submitStatus === 'PENDING'">Šaljem...</p>
                        <p class="typo__p error-txt" v-if="submitError !== ''">{{ submitError }}</p>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { helpers, required, maxLength } from 'vuelidate/lib/validators'
const character_map = helpers.regex('character_map', /^[a-zA-Z0-9Ć-ćČ-čĐ-đŠ-šŽ-ž\-_':;.,#()!?+ \s]*$/)
export default {
    data() {
        return {
            subject_show: true,
            form_id: 'send_message',
            subject: '',
            message_text: '', 
            message_type: null,
            form_enabled: false,   
        }
    },
    props: ['item_id', 'order_id'],
    computed: {
        ...mapGetters({
            user: 'user/getUser',
            userCredentials: 'user/getCredentials',
            message_types: 'messages/getMessageTypes',
            submitStatus: 'forms/submitStatus',
            submitError: 'forms/submitError',
        }),
        message_typeErrors () {
            const errors = []
            if (!this.$v.message_type.$dirty) return errors
            !this.$v.message_type.required && errors.push('Vrsta poruke je obavezno polje')
            return errors
        },
        subjectErrors () {
            const errors = []
            if (!this.$v.subject.$dirty) return errors
            !this.$v.subject.maxLength && errors.push('Maksimum 150 znakova')
            !this.$v.subject.character_map && errors.push('Predmet zadrži nedozvoljeni znak')
            !this.$v.subject.required && errors.push('Naslov je obavezan')
            return errors
        },
        message_textErrors () {
            const errors = []
            if (!this.$v.message_text.$dirty) return errors
            !this.$v.message_text.maxLength && errors.push('Maksimum 500 znakova')
            !this.$v.message_text.character_map && errors.push('Poruka zadrži nedozvoljeni znak')
            !this.$v.message_text.required && errors.push('Poruka je obavezna')
            return errors
        }
    },
    mixins: [validationMixin],
    validations: {
        message_type:{
            required,
        },
        subject: {
            character_map,
            required,
            maxLength: maxLength(150),
        },
        message_text: {
            required,
            character_map,
            maxLength: maxLength(500),
        }
    },
    methods: {
        changeStatus(){
            this.status = (this.status==1)?0:1
        },
        changeSettings(name){
            this.settings[name] = (this.settings[name]==1)?0:1 
        },
        submit () {
            var formPass = false
            
            console.log(this.$v.$touch())
            formPass = this.$v.$invalid
            if (formPass) {
                this.$store.dispatch('forms/changeSubmitStatus','ERROR')
            } else {
                this.$store.dispatch('forms/changeSubmitStatus','PENDING')
                let formData = {
                    subject: this.subject,
                    message_type: this.message_type,
                    message_text: this.message_text,
                    user_id: this.user.id,
                    order_id: this.$route.params.order_id,
                    prod_id: this.$route.params.item_id,
                }
                this.$store.dispatch('forms/submitForm', { form: this.form_id, data: formData })
            }
        },
        back(){
            this.$router.go(-1)
        }
    },
    mounted(){
        if(this.$route.params.order_id>0){
            this.subject = 'Poruka iz narudžbe #'+this.$route.params.order_id+((this.$route.params.item_id)?'-'+this.$route.params.item_id:'')
            this.subject_show = false
        }
    },
    beforeCreate(){
        this.$store.dispatch('messages/getAllMessageTypes')
        if(this.$store.getters['user/getCredentials'].reklamacije == 0) this.$router.push({ name: 'not_auth' })
    },
    destroyed(){
        this.$store.dispatch('forms/changeSubmitStatus',null)
        this.$store.dispatch('forms/changeSubmitError',null)
    }
}
</script>
<style scoped>
    .order_holder{
        box-shadow:0 2px 3px rgba(0,0,0,.07);
        margin-top:20px;
        padding:7px;
    }
    .order_sum {
        float: left !important;
        margin: 10px 90px 10px 10px;
        font-size: 1.1rem;
        font-weight: 500;
    }
    .order_dates{
        background-image:url('/img/strela.png');
        background-position: center center;
        background-repeat: none;
        background-size: 40px;
    }
    .router_right{
        float:right;
        margin-top:10px;
    }
    .order_header{
        width:100%;
        height:60px;
        min-height:60px !important;
        line-height: 60px;
        position:relative;
        padding:10px 10px 10px 60px !important;
        border-bottom:1px solid rgba(0,0,0,.03);
    }
    .product_holder, .package_holder, .bottom_order{
        position:relative;
        width:100%;
        min-height:50px;
        line-height:20px;
        padding:10px 10px 10px 10px;
        border-bottom:1px solid rgb(226, 226, 226);
    }
    .package_holder .product_holder{
        border:none;
        padding:0;
        color:rgb(143, 143, 143);
    }
    .bottom_order{
        height:50px;
        padding-top:15px;
        border:none;
        padding:0 !important;
        border-top:1px solid #000;
    }
    .product_img{
        position:absolute;
        left:0px;
        top:5px;
        width:70px;
        height:70px;
    }
    .product_title{
        min-height:20px;
        line-height:20px;
        position:relative;
    }
    .product_title span{
        position: absolute;
        right: 0;
        text-align: right;
        display: block;
        top: 0;
    }
    .blur{
        position:relative;
    }
    .blur::after{
        content:"";
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background: rgba(255,255,255,.5);
    }
    .gray_cycle{
        position:absolute;
        left:18px;
        top:18px;
        width:24px;
        height:24px;
        border-radius:50%;
        background:#f7f7f7;
    }
    .item_icon, .bottom_order_icon{
        position:absolute;
        left:4px;
        top:4px;
        width:16px;
        height:16px;
    }
    .bottom_order_icon{
        left:24px;
        top:21px;
        width:24px;
        height:24px;
    }
    .top{
        bottom:unset;
        top:10px;
    }
</style>>