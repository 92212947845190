<template>
    <v-container>
        <v-row dense>
            <v-col cols="12">
                <MessageAddComponent />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import MessageAddComponent from '../components/MessageAddComponent.vue'
export default {
    data() {
        return {
        }
    },
    components: { MessageAddComponent },
}
</script>
